<template>
  <b-container class="container-content">
    <div class="page-header">
      <h4 class="page-header-text">{{$t('pages.policy.heading')}}</h4>
    </div>
    <PrivacyPolicyContent :hideHeader="true" />
  </b-container>
</template>

<script>
import PrivacyPolicyContent from '../components/Modal/PrivacyPolicyContent'
export default {
  components: { PrivacyPolicyContent }
}
</script>
